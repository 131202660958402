.custom-swiper-container {
  --swiper-arrow-color: #1a202c;
  --swiper-pagination-color: #6ff25a;
  --swiper-pagination-bullet-inactive-color: #717479;
}

.custom-swiper-container[data-theme='dark'] {
  --swiper-arrow-color: #f8fafc;
  --swiper-pagination-color: #6ff25a;
  --swiper-pagination-bullet-inactive-color: #ffffff;
}

.custom-swiper-container .text-swiper-arrow {
  color: var(--swiper-arrow-color);
}

.swiper-pagination-bullet {
  background-color: var(--swiper-pagination-bullet-inactive-color) !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: var(--swiper-pagination-color) !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.swiper-pagination {
  position: static !important;
  width: auto !important;
}

.custom-swiper {
  cursor: grab;
}

.custom-swiper .swiper-button-next,
.custom-swiper .swiper-button-prev {
  top: auto !important;
  bottom: 10px !important;
  color: var(--swiper-arrow-color);
}

.custom-swiper .swiper-button-prev {
  left: 10px !important;
}

.custom-swiper .swiper-button-next {
  right: 10px !important;
}

.custom-swiper .swiper-pagination {
  bottom: 20px !important;
}

.custom-swiper .swiper-pagination-bullet {
  background-color: var(--swiper-pagination-bullet-inactive-color);
}

.custom-swiper .swiper-pagination-bullet-active {
  background-color: var(--swiper-pagination-color);
}
