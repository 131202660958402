html {
  cursor: default;
  scroll-behavior: smooth;
  line-height: 1.5;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent;
  word-break: break-word;
  font-variant-numeric: normal;
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI (Custom)',
    Roboto,
    'Helvetica Neue',
    'Open Sans (Custom)',
    system-ui,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji';
}

html,
body {
  height: 100%;
}

#root,
#__next {
  height: 100%;
  isolation: isolate;
}
